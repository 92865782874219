var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mex-data-table',{attrs:{"dense":"","data":_vm.clinicTableData,"enableServerSidePagination":true,"headers":_vm.getDynamicTableHeaders,"hide-default-footer":true,"isLoading":_vm.clinicTableLoading,"loadingText":"Loading clinic data...","srvSideActPage":_vm.actPage,"srvSideActSize":_vm.actSize,"srvSideSizeItems":_vm.getTablePagination.rowsPerPageWithNames,"srvSideTotalPages":_vm.totalPages,"srvSideTotalItems":_vm.totalItems,"item-key":"ClinicID","show-expand":"","single-expand":"","tableClass":"foreground"},on:{"pageChanged":_vm.handlePageChange,"sizeChanged":_vm.handleSizeChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('mex-btn',{attrs:{"icon":"mdi-refresh","iconOnly":"","tooltip":"refresh the clinic overview","tooltipPosition":"top"},on:{"click":_vm.fetchClinicOverviewData}})],1)],1)]},proxy:true},(_vm.readClinic)?{key:"item.goToClinic",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToClinicView(item.ClinicID)}}},[_vm._v(_vm._s(item.name))])]}}:{key:"item.goToClinic",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},(_vm.readOrganization)?{key:"item.goToOrganization",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToOrganizationView(item.Organization.OrganizationID)}}},[_vm._v(" "+_vm._s(item.Organization.name)+" ")])]}}:{key:"item.goToOrganization",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Organization.name)+" ")]}},{key:"item.lastContact",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkIfLastContactExists(item))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [(item.managedManually)?_c('div',[_c('v-tooltip',{attrs:{"color":"blue","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({staticStyle:{"background-color":"blue","box-shadow":"inset -1px -1px 0px #000, 1px 1px 2px black, inset 0px 0px 5px black, 1px 1px 2px blue, 0px 0px 10px blue"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" this clinic is managed manually ")])])],1):_c('div',[(_vm.$lastContactEvaluation.lastContactWithin24h(item.lastContact))?_c('v-tooltip',{attrs:{"color":"success","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({attrs:{"color":"success"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" last contact was within the last 24 hours ")])]):(!_vm.$lastContactEvaluation.lastContactWithin48h(item.lastContact))?_c('v-tooltip',{attrs:{"color":"error","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({attrs:{"blink":"","color":"error"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" The last contact was outside the last 48 hours ")])]):_c('v-tooltip',{attrs:{"color":"warning","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('mex-status-lamp',_vm._g(_vm._b({attrs:{"blink":"","color":"warning"}},'mex-status-lamp',attrs,false),on))]}}],null,true)},[_c('span',[_vm._v(" The last contact outside the last 24 hours ")])])],1)]}},{key:"item.deactivationState",fn:function(ref){
var item = ref.item;
return [(_vm.isDeactivated(item))?_c('v-tooltip',{attrs:{"color":"error","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-alert")])]}}],null,true)},[_c('span',[_vm._v(" clinic is deactivated "+_vm._s(_vm.$deactivationStateEvaluation.isForcedDeactivationSet(item.Locations[0].forcedDeactivationDate) ? '(FORCED) ' : '')+" since "+_vm._s(_vm.$dateFormatter.convertJsonDate(item.Locations[0].forcedDeactivationDate).date)+" ")])]):(item.Locations[0] ? _vm.$deactivationStateEvaluation.isForcedDeactivationSet(item.Locations[0].forcedDeactivationDate) : false)?_c('v-tooltip',{attrs:{"color":"warning","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"warning"}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-open-alert")])]}}],null,true)},[_c('span',[_vm._v("forced deactivation date is set: "+_vm._s(_vm.$dateFormatter.convertJsonDate(item.Locations[0].forcedDeactivationDate).date))])]):_c('v-tooltip',{attrs:{"color":"success","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-lock-open-check")])]}}],null,true)},[_c('span',[_vm._v("dynamic deactivation date is set")])])]}},{key:"item.blocklisted",fn:function(ref){
var item = ref.item;
return [(item.blocklisted)?_c('v-tooltip',{attrs:{"right":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"error"}},[_vm._v(" mdi-headset-off ")])],1)]}}],null,true)},[_c('span',[_vm._v(" clinic is blocklisted ")])]):_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-headset")])]}}],null,true)},[_c('span',[_vm._v("clinic is not blocklisted")])])]}},{key:"item.OrganizationSystemUsers",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-triangle-small-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.Organization.SystemUsers),function(systemUser){return _c('v-list-item',{key:systemUser,attrs:{"dense":""}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-account")])],1),_c('v-list-item-title',{staticClass:"ma-1",domProps:{"textContent":_vm._s(systemUser.username)}})],1)}),1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"extendedClinicRow",attrs:{"colspan":headers.length}},[_c('location-overview',{staticClass:"ma-3",attrs:{"clinicID":item.ClinicID,"hideClinicGoTo":_vm.hideClinicsGoTo,"hideHeader":true,"hideOrgaGoTo":_vm.hideOrganizationsGoTo,"locationOverviewElevation":'0',"locations":item.Locations,"organizationID":item.Organization.OrganizationID}})],1)]}},{key:"item.meditexBackupState",fn:function(ref){
var item = ref.item;
return [(item.meditexBackupState === 0)?_c('v-tooltip',{attrs:{"color":"gray","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"gray"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pause-circle")])]}}],null,true)},[_c('span',[_vm._v(" Clinic has MedITEX-Backup-Checks deactivated ")])]):(item.meditexBackupState === 1)?_c('v-tooltip',{attrs:{"color":"success","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-check-circle")])]}}],null,true)},[_c('span',[_vm._v("Clinic reported MedITEX backup in the last 24 hours")])]):_c('v-tooltip',{attrs:{"color":"error","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"error"}},'v-icon',attrs,false),on),[_vm._v(" mdi-close-circle")])]}}],null,true)},[_c('span',[_vm._v("Clinic did NOT report a MedITEX backup in the last 24 hours")])])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }