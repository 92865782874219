<template>
  <mex-data-table
    dense
    :data="clinicTableData"
    :enableServerSidePagination="true"
    :headers="getDynamicTableHeaders"
    :hide-default-footer="true"
    :isLoading="clinicTableLoading"
    loadingText="Loading clinic data..."
    :srvSideActPage="actPage"
    :srvSideActSize="actSize"
    :srvSideSizeItems="getTablePagination.rowsPerPageWithNames"
    :srvSideTotalPages="totalPages"
    :srvSideTotalItems="totalItems"
    item-key="ClinicID"
    show-expand
    single-expand
    tableClass="foreground"
    @pageChanged="handlePageChange"
    @sizeChanged="handleSizeChange"
  >
    <template v-slot:top>
      <v-row justify="end">
        <v-col cols="auto">
          <mex-btn icon="mdi-refresh" iconOnly tooltip="refresh the clinic overview" tooltipPosition="top" @click="fetchClinicOverviewData" />
        </v-col>
      </v-row>
    </template>
    <template v-if="readClinic" v-slot:item.goToClinic="{ item }">
      <v-btn style="font-size: smaller; height: fit-content" class="pa-1" color="foreground" small @click="goToClinicView(item.ClinicID)">{{ item.name }}</v-btn>
    </template>
    <template v-else v-slot:item.goToClinic="{ item }">
      {{ item.name }}
    </template>
    <template v-if="readOrganization" v-slot:item.goToOrganization="{ item }">
      <v-btn class="pa-1" style="font-size: smaller; height: fit-content" color="foreground" small @click="goToOrganizationView(item.Organization.OrganizationID)">
        {{ item.Organization.name }}
      </v-btn>
    </template>
    <template v-else v-slot:item.goToOrganization="{ item }">
      {{ item.Organization.name }}
    </template>
    <template v-slot:item.lastContact="{ item }">
      {{ checkIfLastContactExists(item) }}
    </template>
    <template v-slot:item.state="{ item }">
      <div v-if="item.managedManually">
        <v-tooltip color="blue" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" style="background-color: blue;box-shadow: inset -1px -1px 0px #000, 1px 1px 2px black, inset 0px 0px 5px black, 1px 1px 2px blue, 0px 0px 10px blue;" />
          </template>
          <span> this clinic is managed manually </span>
        </v-tooltip>
      </div>
      <div v-else>
        <v-tooltip v-if="$lastContactEvaluation.lastContactWithin24h(item.lastContact)" color="success" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" color="success"/>
          </template>
          <span> last contact was within the last 24 hours </span>
        </v-tooltip>
        <v-tooltip v-else-if="!$lastContactEvaluation.lastContactWithin48h(item.lastContact)" color="error" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" blink color="error" />
          </template>
          <span> The last contact was outside the last 48 hours </span>
        </v-tooltip>
        <v-tooltip v-else color="warning" right>
          <template v-slot:activator="{ on, attrs }">
            <mex-status-lamp v-bind="attrs" v-on="on" blink color="warning" />
          </template>
          <span> The last contact outside the last 24 hours </span>
        </v-tooltip>
      </div>
    </template>
    <template v-slot:item.deactivationState="{ item }">
      <v-tooltip v-if="isDeactivated(item)" color="error" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="error"> mdi-lock-alert</v-icon>
        </template>
        <span>
          clinic is deactivated
          {{ $deactivationStateEvaluation.isForcedDeactivationSet(item.Locations[0].forcedDeactivationDate) ? '(FORCED) ' : '' }}
          since
          {{ $dateFormatter.convertJsonDate(item.Locations[0].forcedDeactivationDate).date }}
        </span>
      </v-tooltip>
      <v-tooltip
        v-else-if="item.Locations[0] ? $deactivationStateEvaluation.isForcedDeactivationSet(item.Locations[0].forcedDeactivationDate) : false"
        color="warning"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="warning"> mdi-lock-open-alert</v-icon>
        </template>
        <span>forced deactivation date is set: {{ $dateFormatter.convertJsonDate(item.Locations[0].forcedDeactivationDate).date }}</span>
      </v-tooltip>
      <v-tooltip v-else color="success" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="success"> mdi-lock-open-check</v-icon>
        </template>
        <span>dynamic deactivation date is set</span>
      </v-tooltip>
    </template>
    <template v-slot:item.blocklisted="{ item }">
      <v-tooltip v-if="item.blocklisted" right color="error">
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on">
              <v-icon small color="error"> mdi-headset-off </v-icon>
          </div>
        </template>
        <span> clinic is blocklisted </span>
      </v-tooltip>
      <v-tooltip v-else right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on"> mdi-headset</v-icon>
        </template>
        <span>clinic is not blocklisted</span>
      </v-tooltip>
    </template>
    <template v-slot:item.OrganizationSystemUsers="{ item }">
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            x-small
            class="white--text"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-triangle-small-down</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            dense
            v-for="systemUser in item.Organization.SystemUsers"
            :key="systemUser"
          >
            <v-list-item-icon class="mr-1">
              <v-icon x-small>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title class="ma-1" v-text="systemUser.username"></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="extendedClinicRow">
        <location-overview
          :clinicID="item.ClinicID"
          :hideClinicGoTo="hideClinicsGoTo"
          :hideHeader="true"
          :hideOrgaGoTo="hideOrganizationsGoTo"
          :locationOverviewElevation="'0'"
          :locations="item.Locations"
          :organizationID="item.Organization.OrganizationID"
          class="ma-3"
        />
      </td>
    </template>
    <template v-slot:item.meditexBackupState="{ item }">
      <v-tooltip v-if="item.meditexBackupState === 0" color="gray" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="gray"> mdi-pause-circle</v-icon>
        </template>
        <span>
          Clinic has MedITEX-Backup-Checks deactivated
        </span>
      </v-tooltip>
      <v-tooltip
        v-else-if="item.meditexBackupState === 1"
        color="success"
        right
      >
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="success"> mdi-check-circle</v-icon>
        </template>
        <span>Clinic reported MedITEX backup in the last 24 hours</span>
      </v-tooltip>
      <v-tooltip v-else color="error" right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon small v-bind="attrs" v-on="on" color="error"> mdi-close-circle</v-icon>
        </template>
        <span>Clinic did NOT report a MedITEX backup in the last 24 hours</span>
      </v-tooltip>
    </template>
  </mex-data-table>
</template>

<script>
import { mapGetters } from 'vuex';
import ClinicsService from '../../services/clinics.service';
import LocationOverview from './LocationOverview.vue';
import tablePagination from '../../config/tablePagination.config';

export default {
  name: 'ClinicsTable',
  props: {
    excludeHeaders: {
      type: Array,
      default: () => {
        return [];
      },
      description: '',
    },
    hideClinicGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    hideOrgaGoTo: {
      type: Boolean,
      default: false,
      description: '',
    },
    filterData: {
      type: Object,
      default: () => {
        return {
          clinicName: null,
          organizationName: null,
          registerType: null,
          state: null,
          deactivation: null,
          systemUserIDs: null,
          meditexBackupState: null,
        };
      },
      description: '',
    },
  },
  components: {
    LocationOverview,
  },
  computed: {
    ...mapGetters('sysAuth', ['getUserPermissions']),
    getDynamicTableHeaders() {
      return this.clinicTableHeaders.filter((x) => !this.excludeHeaders.includes(x.value));
    },
    readOrganization() {
      return this.getUserPermissions.includes('read_Customer Administration_Organization Administration');
    },
    readClinic() {
      return this.getUserPermissions.includes('read_Customer Administration_Clinic Administration');
    },
    hideClinicsGoTo() {
      if (this.hideClinicGoTo || !this.readClinic) {
        return true;
      }
      return false;
    },
    hideOrganizationsGoTo() {
      if (this.hideOrgaGoTo || !this.readOrganization) {
        return true;
      }
      return false;
    },
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      // visualization
      clinicTableLoading: false,
      // clinic table
      actPage: 1,
      actSize: tablePagination.defaultRowsPerPage,
      totalItems: 0,
      totalPages: 0,
      clinicTableData: [],
      clinicTableHeaders: [
        {
          text: 'Clinic name',
          align: 'start',
          sortable: true,
          value: 'goToClinic',
        },
        {
          text: 'Organization',
          align: 'start',
          sortable: false,
          value: 'goToOrganization',
        },
        {
          text: 'MedITEX Version',
          align: 'start',
          sortable: true,
          value: 'Infrastructures[0].meditexVersion',
        },
        {
          text: 'Register Type',
          align: 'start',
          sortable: true,
          value: 'RegisterType.acronym',
        },
        {
          text: 'Last contact',
          align: 'start',
          sortable: true,
          value: 'lastContact',
        },
        {
          text: 'State',
          align: 'center',
          sortable: false,
          value: 'state',
        },
        {
          text: 'Blocklisted',
          align: 'center',
          sortable: false,
          value: 'blocklisted',
        },
        {
          text: 'Deactivation State',
          align: 'center',
          sortable: false,
          value: 'deactivationState',
        },
        {
          text: 'MedITEX Backup',
          align: 'center',
          sortable: false,
          value: 'meditexBackupState',
        },
        {
          text: 'System Users',
          align: 'start',
          sortable: false,
          value: 'OrganizationSystemUsers',
        },
      ],
      meditexBackupStateMapper: {
        0: "Not checked",
        1: "Reported",
        2: "Overdue"
      }
    };
  },
  watch: {
    filterData: {
      handler() {
        this.fetchClinicOverviewData();
      },
      deep: true,
    },
  },
  methods: {
    fetchClinicOverviewData() {
      this.clinicTableLoading = true;
      ClinicsService.getClinicsOverview(this.actPage, this.actSize, this.filterData)
        .then((response) => {
          this.clinicTableData = response.data.data;
          this.totalItems = response.data.totalItems;
          this.totalPages = response.data.totalPages;
          this.clinicTableLoading = false;
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
          this.clinicTableLoading = false;
        });
    },
    handlePageChange(value) {
      this.actPage = value;
      this.fetchClinicOverviewData();
    },
    handleSizeChange(value) {
      this.actPage = 1;
      this.actSize = value;
      this.fetchClinicOverviewData();
    },
    isDeactivated(item) {
      if (item.Locations && item.Locations[0] && item.Locations[0].forcedDeactivationDate && item.Locations[0].deactivationDate) {
        return (
          this.$deactivationStateEvaluation.isDeactivated(item.Locations[0].forcedDeactivationDate) ||
          this.$deactivationStateEvaluation.isDeactivated(item.Locations[0].deactivationDate)
        );
      }
      return null;
    },
    goToOrganizationView(id) {
      this.$router.push({ name: 'OrganizationView', params: { id } });
    },
    goToClinicView(id) {
      this.$router.push({ name: 'ClinicView', params: { id } });
    },
    checkIfLastContactExists(item) {
      return item.lastContact ? item.lastContact.replace('Z', '').replace('T', ' ').substring(0, 16) : null;
    },
  },
  mounted() {
    this.fetchClinicOverviewData();
  },
};
</script>

<style>
.extendedClinicRow {
  box-shadow: inset 0 0 1em var(--v-primary-base);
}
</style>
